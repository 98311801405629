<template>
  <div class="helpList">
    <div class="main-box">
      <div class="breadcrumb">
        <router-link :to="{name:'helpCenter'}"> 帮助中心 / </router-link>
        <span>{{categoryName}}</span>
      </div>
      <div class="content">
        <div class="types">
          <div @click="typeCg(item.id)" v-for="(item) in types" :key="item.id" :class="{active:item.id===type}">{{item.categoryName}}</div>
        </div>
        <div class="list">
          <div v-for="(item,index) in texts" :key="index">
            <div class="question" @click="getHelpcontentInfo (item.id)">
              {{item.contentTitle}}
              <svg-icon class="icon" :class="{rotate:showAnswer==item.id}" icon-class="pulldown" />
            </div>
            <el-collapse-transition>
              <div v-if="item.id==showAnswer">
                <div ref="answer" class="answer" v-html="answer.contentText" :class="{long:answer.contentText&&answer.contentText.length>460&&answer.id!==showMore}">
                </div>
                <template v-if="answer.contentText&&answer.contentText.length>460">
                  <el-button v-if="item.id!==showMore" class="more" @click.stop="showMore=item.id" type="text">—>查看全部</el-button>
                  <el-button v-else class="more" @click.stop="showMore=-1" type="text">—>收起</el-button>
                </template>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type: 5,
      types: [],
      showAnswer: -1,
      showMore: -1,
      answer: {},
      texts: [],
      pageIndex: 1,
      pageSize: 10
    }
  },
  created () {
    this.getContentcategory()
  },
  mounted () {
    this.texts = []
  },
  methods: {
    RandomNumBoth (min, max) {
      var range = max - min
      var rand = Math.random()
      var num = min + Math.round(rand * range) // 四舍五入
      return num
    },
    getContentcategory () {
      this.$api.getContentcategory({ categoryType: 2 }).then(res => {
        this.types = res.data
        let type = this.$route.query.type
        this.type = type || type === 0 ? type : res.data[0].id
        this.getHelpcontentList(type)
      })
    },
    getHelpcontentList (type) {
      this.$api
        .getHelpcontentList({
          categoryId: this.type,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        })
        .then(res => {
          if (type === this.type) {
            this.texts.push(...res.data.list)
            if (res.data.hasNext) {
              this.pageIndex++
              this.getHelpcontentList(type)
            }
          }
        })
    },
    getHelpcontentInfo (id) {
      if (this.showAnswer !== id) {
        this.$api.getHelpcontentInfo({ id }).then(res => {
          this.answer = res.data || {}
          this.$nextTick(() => {
            this.showAnswer = id
          })
        })
      }
      this.showAnswer = -1
    },
    typeCg (type) {
      if (type !== this.type) {
        this.showAnswer = -1
        this.type = type
        this.pageIndex = 1
        this.$router.push({
          query: { type }
        })
        this.texts = []

        this.getHelpcontentList(type)
      }
    }
  },
  computed: {
    categoryName () {
      let name = ''
      for (let type of this.types) {
        if (type.id === this.type) {
          name = type.categoryName
        }
      }
      return name
    }
  }
}
</script>

<style lang="scss" scoped>
.answerDom {
  width: 926px;
  position: fixed;
  line-height: 36px;
  left: -100%;
}
.helpList {
  padding-top: 60px;
  background: #f9fbff;
  padding-bottom: 160px;
  .breadcrumb {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    line-height: 19px;
    margin-top: 32px;
    margin-bottom: 24px;
    a {
      color: #a2a9b4;
    }
    span {
      color: #303b50;
      font-weight: bold;
    }
  }
  .content {
    display: flex;
    align-items: flex-start;
    .types {
      width: 258px;
      background: #ffffff;
      border-radius: 2px;
      padding: 32px 0;
      font-size: 14px;
      color: #303b50;
      margin-right: 16px;
      flex-shrink: 0;
      & > div {
        cursor: pointer;
        padding-left: 14px;
        margin-bottom: 16px;
        line-height: 35px;
        border-left: 2px transparent solid;
        &:last-child {
          margin: 0;
        }
        &.active {
          color: $primaryColor;
          border-left: 2px $primaryColor solid;
        }
        &:hover {
          color: $primaryColor;
        }
      }
    }
    .list {
      background: #ffffff;
      width: 100%;

      & > div {
        padding: 40px 50px;
        border-bottom: 1px dashed #d9d9d9;
        &:last-child {
          border: none;
        }
        .question {
          font-size: 16px;
          font-weight: bold;
          color: #303b50;
          line-height: 21px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:hover {
            color: $primaryColor;
            .icon {
              color: $primaryColor;
            }
          }
          .icon {
            color: #d4d4da;
            font-size: 14px;
            font-weight: normal;
            transition: all 0.2s;
            &.rotate {
              transform: rotate(180deg);
            }
          }
        }
        .answer {
          font-size: 14px;
          color: #303b50;
          line-height: 36px;
          position: relative;
          transition: all 0.3s;
          // max-height: 1000px;
          overflow: hidden;
          padding-top: 22px;
          &.long {
            max-height: 256px;

            &::after {
              content: '';
              position: absolute;
              height: 100%;
              width: 100%;
              top: 0;
              left: 0;
              background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(255, 255, 255, 0.96) 100%
              );
            }
          }
        }
        .more {
          margin-top: 8px;
        }
      }
    }
  }
}
</style>
